import '@esri/calcite-components/dist/calcite/calcite.css';
import {
  BrowserRouter,
  Routes,
  Route,
  // Link,
  useNavigate
} from "react-router-dom";
import {
  ClimateConditions,
  ExistingHealth,
  Hvi,
  EnviroExposure,
  SocioDemo,
  ScrollToTop
} from './components'
import { Homepage } from './pages';
import { useDispatch, useSelector } from 'react-redux';
import { setNavLoc } from './features/navLocSlice'
import njaesLogo from './data/images/logos/RUTGERS_H_WHITE.png'
import { useEffect } from 'react';
import { useGoogleAnalytics } from './tools/googleAnalytics';

// import { FaBars } from 'react-icons/fa';
// import esriConfig from '@arcgis/core/config'

const Header = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const navLoc = useSelector(state => state.navLoc)
  const county = useSelector(state => state.county)
  const muni = useSelector(state => state.muni)

  let path = window.location.pathname
  let topic = path.split('/')[2]
  // console.log('topic', topic) 


  const handleClick = ({ target }) => {
    let { value } = target
    if (value === undefined) {
      value = 'all'
    }
    // set selected
    dispatch(setNavLoc(value))
    // navigate to selected
    navigate(`/${value}`)
  }

  useEffect(() => {
    if (topic === undefined || topic.length === 0 || topic === 'all') {
      dispatch(setNavLoc('all'))
    }

  }, [topic, dispatch])
  // console.log(county, muni)

  return (
    <header className='nav print-hidden'>
      <div className='nav-width'>

        <div className='title' onClick={handleClick}> <img src={njaesLogo} alt='New Jersey Agricultural Experiment Station' style={{ width: '150px' }} />{process.env.REACT_APP_FULL_NAME}</div>
        <div className='nav-links'>
          <select onChange={handleClick} value={navLoc}>
            <option
              disabled={!county && !muni}
              value='all' >All Reports</option>
            <option
              // disabled={(!county && !muni) || muni?.climLoading || county?.climLoading} 
              value='climate-cond' >Climate Impacts</option>
            <option
              // disabled={(!county && !muni) || muni?.healthLoading || county?.healthLoading} 
              value='exist-health' >Existing Health Conditions</option>
            <option
              // disabled={(!county && !muni) || muni?.heatLoading || county?.heatLoading} 
              value='hvi' >Heat Vulnerability Index</option>
            <option
              // disabled={(!county && !muni) || muni?.enviroLoading || county?.enviroLoading} 
              value='enviro-expos' >Environmental Exposure</option>
            <option
              // disabled={(!county && !muni) || muni?.socioLoading || county?.socioLoading} 
              value='socio-demo' >Socio-demographics</option>
          </select>
          {/* <FaBars/> */}
        </div>
      </div>
    </header>
  )
}

const App = () => {

  useGoogleAnalytics()
  // esriConfig.apiKey = process.env.REACT_APP_ESRI_API_KEY

  console.log('backend', process.env.REACT_APP_SERVER_URL)
  return (
    <div className='app-container'>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <ScrollToTop>
          <Header />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/all" element={<Homepage />} />
            <Route path="/climate-cond/:locType/:locId" element={<ClimateConditions />} />
            <Route path="/climate-cond" element={<ClimateConditions />} />
            <Route path="/enviro-expos/:locType/:locId" element={<EnviroExposure />} />
            <Route path="/enviro-expos" element={<EnviroExposure />} />
            <Route path="/exist-health" element={<ExistingHealth />} />
            <Route path="/hvi/:locType/:locId" element={<Hvi />} />
            <Route path="/hvi" element={<Hvi />} />
            <Route path="/socio-demo/:locType/:locId" element={<SocioDemo />} />
            <Route path="/socio-demo" element={<SocioDemo />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter> </div>
  );
}

export default App;
